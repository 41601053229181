import React, { useState } from 'react';

const UserDropdown: React.FC = () => {
  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false);

  return (
    <div className="relative">
      <button
        onClick={() => setIsDropdownVisible(!isDropdownVisible)}
        className="text-2xl dark:text-gray-100"
      >
        👤
      </button>
      {isDropdownVisible && (
        <div className="absolute left-0 bottom-10 w-48 bg-white dark:bg-gray-800 rounded-md shadow-lg z-50">
          <div className="py-2 px-4 dark:text-gray-100">Joe Bloggs</div>
          <div className="py-2 px-4 border-t border-gray-200 dark:border-gray-700 dark:text-gray-100">
            Logout
          </div>
        </div>
      )}
    </div>
  );
};

export default UserDropdown;
