import React, { useEffect, useState } from 'react';
import { Message } from '../App'
import axios from 'axios';

interface ChatHistoryProps {
  messages: Message[];
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;  
}

const ChatHistory: React.FC<ChatHistoryProps> = ({messages, setMessages}) => {
  const [history, setHistory] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchChatHistory = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_SERVER_URL}/chat_history?user=danielpcowen@gmail.com`); // Adjust the API endpoint as needed
        console.log(response.data)
        setHistory(response.data.chats);
      } catch (error) {
        setError('Failed to fetch chat history');
      } finally {
        setLoading(false);
      }
    };

    fetchChatHistory();
  }, []);

  const handleClick = (index: number) => {
    setMessages(history[index].messages);
  };


  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="mt-8">
      <h2 className="text-xl font-bold dark:text-gray-100 text-center">Chat History</h2>
      <ul className="dark:text-gray-100 mt-4 space-y-2">
        {history.map((conversation, index) => (
          <li key={index}>
           <button
              className="w-full text-left px-4 py-2 rounded-lg bg-gray-300 dark:bg-gray-700 hover:bg-gray-300 dark:hover:bg-gray-600 focus:outline-none"
              onClick={() => handleClick(index)}
            >
              {`${conversation.messages[conversation.messages.length - 2].content.slice(0, 30)}...`}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ChatHistory;
