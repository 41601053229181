import React, { useState } from 'react';
import { FiUser, FiCopy, FiRefreshCw, FiSettings } from 'react-icons/fi';
import MarkdownRenderer from './MarkdownRenderer';
import { useCopyToClipboard } from '../hooks/useCopyToClipboard';
import AudioPlayer from './AudioPlayer';
import ImagePreviewModal from './ImagePreviewModal';
import { Message as ChatMessage } from '../App'
import { FileMetadata } from './FileUpload';

interface ChatPanelProps {
  messages: ChatMessage[];
}

const ChatPanel: React.FC<ChatPanelProps> = ({ messages }) => {
  const { isCopied, copyToClipboard } = useCopyToClipboard();
  const bot_name = process.env.REACT_APP_BOT_NAME;
  const bot_img_src = `/images/${process.env.REACT_APP_BOT_NAME}_small.png`;

  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const renderFileThumbnail = (file: FileMetadata) => {
    const fileType = file.contents.type;
    const file_src = file.base64 ? file.base64 : URL.createObjectURL(file.contents)
    const fileIcon = fileType.startsWith('image/') || (file.base64 && file.base64.startsWith('data:image/')) ? (
      <img
        src={file_src}
        alt={file.filename}
        className="w-64 h-64 object-cover cursor-pointer"
        onClick={() => setSelectedImage(file_src)}
      />
    ) : (
      <div className="w-12 h-12 flex items-center justify-center bg-gray-200 dark:bg-gray-700 text-2xl">
        {getFileIcon(fileType)}
      </div>
    );

    return (
      <div key={file.filename} className="mr-2 mb-2">
        {fileIcon}
      </div>
    );
  };

  const getFileIcon = (fileType: string) => {
    switch (fileType) {
      case 'application/pdf':
        return '📄';
      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return '📄';
      case 'application/vnd.ms-powerpoint':
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        return '📊';
      case 'application/vnd.ms-excel':
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return '📊';
      case 'text/csv':
      case 'text/plain':
        return '📃';
      default:
        return '📁';
    }
  };

  return (
    <div className="flex-grow p-4 overflow-auto bg-white dark:bg-gray-800 flex flex-col items-center">
      <div className="w-full max-w-5xl overflow-auto pr-5 pl-5">
      {messages.map((msg, index) => (
        (msg.role === 'assistant' || msg.role === 'user') && (
          <div key={index} className={`mb-3 text-lg flex items-start ${msg.role === 'user' ? 'justify-end' : 'justify-start'} dark:text-gray-100`}>
            {msg.role === 'assistant' && (
              <img src={bot_img_src} alt={bot_name} className="mr-2 w-7 h-7 self-start mt-2" />
            )}
            <div className={`relative p-1 rounded-lg ${msg.role === 'user' ? 'bg-gray-200 dark:bg-gray-900' : ''} ${msg.loading ? 'loading-spinner' : ''} ${msg.role === 'user' ? '' : 'w-full'}`}>
              {msg.files && (
                <div className="flex flex-wrap mb-2">
                  {msg.files.map((file) => renderFileThumbnail(file))}
                </div>
              )}
              <MarkdownRenderer content={msg.content} />
              {msg.role === 'assistant' && !msg.loading && (
                <div className="flex justify-start mt-2 space-x-2 mb-3 mr-9">
                  <button
                    onClick={() => copyToClipboard(msg.content)}
                    className="text-gray-500 hover:text-gray-700 dark:hover:text-gray-300"
                  >
                    <FiCopy />
                  </button>
                  <AudioPlayer text={msg.content} />
                  <button className="text-gray-500 hover:text-gray-700 dark:hover:text-gray-300">
                    <FiSettings />
                  </button>
                </div>
              )}
            </div>
            {msg.role === 'user' && (
              <FiUser className="ml-2 w-7 h-7 self-start mt-2" />
            )}
          </div>
        )
      ))}
      </div>
      <ImagePreviewModal
        show={!!selectedImage}
        onClose={() => setSelectedImage(null)}
        imageSrc={selectedImage}
      />
    </div>
  );
};

/*
                  <button className="text-gray-500 hover:text-gray-700 dark:hover:text-gray-300">
                    <FiRefreshCw />
                  </button>

*/

export default ChatPanel;
