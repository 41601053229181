// src/hooks/useCopyToClipboard.ts
import { useState, useCallback } from 'react';

interface UseCopyToClipboardProps {
  timeout?: number;
}

export const useCopyToClipboard = ({ timeout = 2000 }: UseCopyToClipboardProps = {}) => {
  const [isCopied, setCopied] = useState(false);

  const copyToClipboard = useCallback((text: string | number) => {
    if (typeof text === 'string' || typeof text === 'number') {
      navigator.clipboard.writeText(text.toString());
      setCopied(true);
      setTimeout(() => setCopied(false), timeout);
    } else {
      console.error('Cannot copy non-string and non-number values');
    }
  }, [timeout]);

  return { isCopied, copyToClipboard };
};
