// src/components/MarkdownRenderer.tsx
import React, { memo } from 'react';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { coldarkDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import remarkGfm from 'remark-gfm';
import remarkBreaks from 'remark-breaks';
import remarkHtml from 'remark-html';
import { useCopyToClipboard } from '../hooks/useCopyToClipboard';
import { FiCheck, FiCopy, FiDownload } from 'react-icons/fi';
import Button from './Button';


interface MarkdownRendererProps {
  content: string;
}

type ProgrammingLanguage = 'javascript' | 'python' | 'java' | 'c' | 'cpp' | 'c++' | 'c#' | 'ruby' | 'php' | 'swift' | 'objective-c' | 'kotlin' | 'typescript' | 'go' | 'perl' | 'rust' | 'scala' | 'haskell' | 'lua' | 'shell' | 'sql' | 'html' | 'css';

const programmingLanguages: Record<ProgrammingLanguage, string> = {
  javascript: '.js',
  python: '.py',
  java: '.java',
  c: '.c',
  cpp: '.cpp',
  'c++': '.cpp',
  'c#': '.cs',
  ruby: '.rb',
  php: '.php',
  swift: '.swift',
  'objective-c': '.m',
  kotlin: '.kt',
  typescript: '.ts',
  go: '.go',
  perl: '.pl',
  rust: '.rs',
  scala: '.scala',
  haskell: '.hs',
  lua: '.lua',
  shell: '.sh',
  sql: '.sql',
  html: '.html',
  css: '.css'
};

const generateRandomString = (length: number, lowercase = false) => {
  const chars = 'ABCDEFGHJKLMNPQRSTUVWXY3456789'; // excluding similar looking characters like Z, 2, I, 1, O, 0
  let result = '';
  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return lowercase ? result.toLowerCase() : result;
};

interface CodeBlockProps {
  language: ProgrammingLanguage;
  value: string;
}

const CodeBlock: React.FC<CodeBlockProps> = memo(({ language, value }) => {
  const { isCopied, copyToClipboard } = useCopyToClipboard({ timeout: 2000 });

  const downloadAsFile = () => {
    const fileExtension = programmingLanguages[language] || '.file';
    const suggestedFileName = `file-${generateRandomString(3, true)}${fileExtension}`;
    const fileName = window.prompt('Enter file name', suggestedFileName);

    if (!fileName) {
      return;
    }

    const blob = new Blob([value], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.download = fileName;
    link.href = url;
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const onCopy = () => {
    if (isCopied) return;
    copyToClipboard(value);
  };

  return (
    <div className="relative w-full font-sans codeblock bg-zinc-950 my-2 text-sm">
      <div className="flex items-center justify-between w-full px-6 py-2 pr-4 bg-zinc-800 text-zinc-100">
        <span className="text-xs lowercase">{language}</span>
        <div className="flex items-center space-x-1">
          <Button
            variant="ghost"
            className="hover:bg-zinc-800 focus-visible:ring-1 focus-visible:ring-slate-700 focus-visible:ring-offset-0"
            onClick={downloadAsFile}
            size="icon"
          >
            <FiDownload />
            <span className="sr-only">Download</span>
          </Button>
          <Button
            variant="ghost"
            size="icon"
            className="text-xs hover:bg-zinc-800 focus-visible:ring-1 focus-visible:ring-slate-700 focus-visible:ring-offset-0"
            onClick={onCopy}
          >
            {isCopied ? <FiCheck /> : <FiCopy />}
            <span className="sr-only">Copy code</span>
          </Button>
        </div>
      </div>
      <SyntaxHighlighter
        language={language}
        style={coldarkDark}
        PreTag="div"
        showLineNumbers
        customStyle={{
          margin: 0,
          width: '100%',
          background: 'transparent',
          padding: '1.5rem 1rem'
        }}
        lineNumberStyle={{
          userSelect: 'none'
        }}
        codeTagProps={{
          style: {
            fontSize: '0.9rem',
            fontFamily: 'var(--font-mono)'
          }
        }}
      >
        {value}
      </SyntaxHighlighter>
    </div>
  );
});
CodeBlock.displayName = 'CodeBlock';

const MarkdownRenderer: React.FC<MarkdownRendererProps> = ({ content }) => {
  return (
    <div className="ml-3 mr-3 mt-3 mb-3 text-sm whitespace-normal markdown-content">
      <ReactMarkdown
        components={{
          code({ node, className, children, ...props }) {
            const match = /language-(\w+)/.exec(className || '');
            return match ? (
              <CodeBlock language={match[1] as ProgrammingLanguage} value={String(children).replace(/\n$/, '')} />
            ) : (
              <code className={`${className} whitespace-normal`} {...props}>
                {children}
              </code>
            );
          },
          h3({ node, children }) {
            return (
              <h3 className="mt-6 mb-3 font-bold text-lg">
                {children}
              </h3>
            );
          },          
          p({ node, children }) {
            return (
              <p className="mt-3 mb-3">
                {children}
              </p>
            );
          },
          ol({ node, children }) {
            return (
              <ol className="mb-3 list-decimal list-inside">
                {children}
              </ol>
            );
          },
          ul({ node, children }) {
            return (
              <ul className="mb-3 list-disc list-inside">
                {children}
              </ul>
            );
          },
          li({ node, children }) {
            return (
              <li className="mt-2 mb-2">
                {children}
              </li>
            );
          },          
          pre({ node, children }) {
            return (
              <div className="mb-4">
                <pre className="mb-0">
                  {children}
                </pre>
              </div>
            );
          }
        }}
        remarkPlugins={[remarkBreaks, remarkGfm, remarkHtml]}
      >
        {content}
      </ReactMarkdown>
    </div>
  );
};

export default MarkdownRenderer;