import React from 'react';

interface ImagePreviewModalProps {
  show: boolean;
  onClose: () => void;
  imageSrc: string | null;
}

const ImagePreviewModal: React.FC<ImagePreviewModalProps> = ({ show, onClose, imageSrc }) => {
  if (!show || !imageSrc) {
    return null;
  }

  const handleOutsideClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
      onClick={handleOutsideClick}
    >
      <div className="relative bg-white dark:bg-gray-800 p-4 rounded shadow-lg w-4/5 h-4/5 max-w-screen-lg max-h-screen flex items-center justify-center">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-2xl text-gray-600 dark:text-gray-100"
        >
          &times;
        </button>
        <img
          src={imageSrc}
          alt="Preview"
          className="max-w-full max-h-full mx-auto"
        />
      </div>
    </div>
  );
};

export default ImagePreviewModal;
