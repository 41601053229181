import React, { useState, useRef, useEffect } from 'react';
import { FiSend, FiPaperclip, FiX } from 'react-icons/fi';
import FileUpload, { FileMetadata } from './FileUpload';
import { resizeImage, fileToBase64 } from './utils';

interface PromptBoxProps {
  input: string;
  setInput: (input: string) => void;
  handleSend: (message: { text: string; files: FileMetadata[] }) => void;
  canSendMessage: boolean;
}

const PromptBox: React.FC<PromptBoxProps> = ({ input, setInput, handleSend, canSendMessage }) => {
  const [files, setFiles] = useState<FileMetadata[]>([]);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const allowedFileTypes = ['image/png', 'image/jpeg'];

  const handleFileUpload = async (newFiles: File[]) => {
    const filteredFiles = newFiles.filter(file => allowedFileTypes.includes(file.type));

    if (filteredFiles.length < newFiles.length) {
      alert('Some files were not uploaded because they are not in an allowed format.');
    }

    if (files.length + filteredFiles.length > 5) {
      alert('You can only upload up to 5 files.');
      return;
    }

    const processedFiles: FileMetadata[] = await Promise.all(
      filteredFiles.map(async (file) => {
        const isImage = file.type.startsWith('image/');
        const base64 = isImage ? await resizeImage(file, 768, 768) : await fileToBase64(file);
        return { filename: file.name, contents: file, base64 };
      })
    );

    setFiles((prevFiles) => [...prevFiles, ...processedFiles]);
  };

  const handlePaste = async (event: React.ClipboardEvent<HTMLTextAreaElement>) => {
    const items = event.clipboardData.files;
    if (items.length > 0) {
      event.preventDefault();
      await handleFileUpload(Array.from(items));
    }
  };

  const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      await handleFileUpload(Array.from(files));
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey && canSendMessage) {
      e.preventDefault();
      handleSend({ text: input, files });
      setFiles([]); // Clear files after sending
    }
  };

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${Math.min(textareaRef.current.scrollHeight, 128)}px`;
    }
  }, [input]);

  return (
    <div
      className="flex flex-col items-center w-full pb-3 bg-white dark:bg-gray-800"
      onDrop={handleDrop}
      onDragOver={(e) => e.preventDefault()}
    >
      <div className="w-full max-w-4xl mb-2">
        <div className="flex flex-wrap mb-2">
          {files.map((file) => (
            <div key={file.filename} className="relative mr-2 mb-2">
              <div className="w-12 h-12 flex items-center justify-center bg-gray-200 dark:bg-gray-700 text-2xl">
                {file.filename.toLowerCase().endsWith('.png') ||
                file.filename.toLowerCase().endsWith('.jpg') ||
                file.filename.toLowerCase().endsWith('.jpeg') ? (
                  <img src={file.base64} alt={file.filename} className="w-12 h-12 object-cover" />
                ) : (
                  <span>{file.filename}</span>
                )}
              </div>
              <button
                onClick={() => setFiles((prevFiles) => prevFiles.filter(f => f.filename !== file.filename))}
                className="absolute top-0 right-0 text-red-500 bg-white rounded-full p-1 hover:text-red-700"
              >
                <FiX />
              </button>
            </div>
          ))}
        </div>
      </div>
      <div className="w-full max-w-4xl">
        <div className="flex items-end w-full p-2 rounded-lg border border-gray-300 dark:border-gray-800 dark:bg-gray-900 bg-gray-100">
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            multiple
            onChange={(e) => handleFileUpload(Array.from(e.target.files || []))}
          />
          <button
            onClick={() => fileInputRef.current?.click()}
            className="text-2xl text-gray-600 dark:text-gray-100 mr-2"
          >
            <FiPaperclip />
          </button>
          <textarea
            ref={textareaRef}
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyPress={handleKeyPress}
            onPaste={handlePaste}
            className="flex-grow p-2 rounded-lg border-none resize-none bg-gray-100 dark:bg-gray-900 dark:text-gray-100 focus:outline-none"
            rows={1}
            style={{ maxHeight: '8rem', overflowY: 'auto' }}
            placeholder="Type your message..."
            disabled={!canSendMessage}
          />
          <button
            onClick={() => handleSend({ text: input, files })}
            className="ml-2 text-2xl text-blue-500 dark:text-blue-700"
            disabled={!canSendMessage}
          >
            <FiSend />
          </button>
        </div>
      </div>
      <p className="text-xs italic text-center mt-2 text-gray-600 dark:text-gray-400">
        {process.env.REACT_APP_BOT_NAME} can, on occasion, make mistakes. Always check important info.
      </p>
    </div>
  );
};

export default PromptBox;
