import React, { useState, useRef } from 'react';
import axios from 'axios';
import { FiVolume2, FiStopCircle } from 'react-icons/fi';
import { FaSpinner } from 'react-icons/fa';

interface AudioPlayerProps {
  text: string;
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({ text }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioUrl, setAudioUrl] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const handleVolumeClick = async () => {
    if (isPlaying && audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      setIsPlaying(false);
    } else {
      try {
        setIsLoading(true);
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_SERVER_URL}/do_tts` || '', { text }, { responseType: 'blob' });
        const url = URL.createObjectURL(new Blob([response.data], { type: 'audio/wav' }));
        setAudioUrl(url);
        setIsPlaying(true);
      } catch (error) {
        console.error('Error fetching audio:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="relative">
      <button onClick={handleVolumeClick} className="text-gray-500 hover:text-gray-700 dark:hover:text-gray-300">
        {isLoading ? <FaSpinner className="animate-spin" /> : (isPlaying ? <FiStopCircle /> : <FiVolume2 />)}
      </button>
      {audioUrl && (
        <audio
          ref={audioRef}
          src={audioUrl}
          autoPlay
          onEnded={() => setIsPlaying(false)}
        />
      )}
    </div>
  );
};

export default AudioPlayer;
