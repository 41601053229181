// src/components/Button.tsx
import React from 'react';
import classNames from 'classnames';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'ghost';
  size?: 'small' | 'icon' | 'large';
}

export const Button: React.FC<ButtonProps> = ({
  variant = 'primary',
  size = 'small',
  className,
  children,
  ...props
}) => {
  const classes = classNames(
    'px-4 py-2 rounded focus:outline-none focus:ring',
    {
      'bg-blue-500 text-gray-100': variant === 'primary',
      'bg-transparent text-gray-700 dark:text-gray-100': variant === 'ghost',
      'p-1': size === 'icon',
      'px-3 py-1 text-sm': size === 'small',
      'px-6 py-3 text-lg': size === 'large',
    },
    className
  );

  return (
    <button className={classes} {...props}>
      {children}
    </button>
  );
};

export default Button;
