import React from 'react';

interface IconSidebarProps extends React.ComponentProps<'svg'> {
  className?: string;
}

const IconSidebar: React.FC<IconSidebarProps> = ({ className, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      fill="currentColor"
      className={`size-4 ${className}`}
      {...props}
    >
      <path d="M216 40H40a16 16 0 0 0-16 16v144a16 16 0 0 0 16 16h176a16 16 0 0 0 16-16V56a16 16 0 0 0-16-16ZM40 56h40v144H40Zm176 144H96V56h120v144Z" />
    </svg>
  );
};

export default IconSidebar;
