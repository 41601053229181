import React from 'react';

interface ToolkitSelectorProps {
  selectedToolkit: string;
  setSelectedToolkit: (toolkit: string) => void;
}

const ToolkitSelector: React.FC<ToolkitSelectorProps> = ({ selectedToolkit, setSelectedToolkit }) => {
  return (
    <div className="mb-4">
      <h2 className="text-lg font-bold dark:text-gray-100">Select Toolkit</h2>
      <div className="flex flex-col space-y-2 mt-2">
        {['Basic Chat', 'Document Search', 'Web Search', 'Image Generation'].map((toolkit) => (
          <button
            key={toolkit}
            onClick={() => setSelectedToolkit(toolkit)}
            className={`py-2 px-4 rounded-lg ${
              selectedToolkit === toolkit
                ? 'bg-blue-500 text-gray-100'
                : 'bg-gray-300 dark:bg-gray-700 text-black dark:text-gray-100 dark:hover:bg-gray-600'
            }`}
          >
            {toolkit}
          </button>
        ))}
      </div>
    </div>
  );
};

export default ToolkitSelector;
