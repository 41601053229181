import React, { useState } from 'react';
import './App.css';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import MessageHandler from './components/MessageHandler';
import { FileMetadata } from './components/FileUpload';

export interface Message {
  role: string;
  content: string;
  loading?: boolean;
  files?: FileMetadata[];
}

const App: React.FC = () => {
  const [isDarkMode, setIsDarkMode] = useState<boolean>(true); // Default to dark mode
  const [isSidebarVisible, setIsSidebarVisible] = useState<boolean>(true);
  const [selectedToolkit, setSelectedToolkit] = useState<string>('Basic Chat');
  const [selectedModel, setSelectedModel] = useState<string>('llama3.1'); // Default model
  const [messages, setMessages] = useState<Message[]>([]);

  return (
    <div className={`h-screen flex ${isDarkMode ? 'dark' : ''}`}>
      <Header 
        toggleSidebar={() => setIsSidebarVisible(!isSidebarVisible)} 
        selectedModel={selectedModel}
        setSelectedModel={setSelectedModel}
      />
      <Sidebar
        isSidebarVisible={isSidebarVisible}
        toggleSidebar={() => setIsSidebarVisible(!isSidebarVisible)}
        isDarkMode={isDarkMode}
        toggleDarkMode={() => setIsDarkMode(!isDarkMode)}
        selectedToolkit={selectedToolkit}
        setSelectedToolkit={setSelectedToolkit}
        messages={messages}
        setMessages={setMessages}
      />
      <MessageHandler 
        isDarkMode={isDarkMode}
        setIsDarkMode={setIsDarkMode}
        isSidebarVisible={isSidebarVisible}
        setIsSidebarVisible={setIsSidebarVisible}
        selectedToolkit={selectedToolkit}
        setSelectedToolkit={setSelectedToolkit}
        selectedModel={selectedModel}
        messages={messages}
        setMessages={setMessages}
      />
    </div>
  );
};

export default App;
