import React, { useState, useEffect } from 'react';
import { io } from 'socket.io-client';
import ChatPanel from './ChatPanel';
import PromptBox from './PromptBox';
import { FileMetadata } from './FileUpload';
import { Message } from '../App'
import { v4 } from 'uuid';

const urlParams = new URLSearchParams(window.location.search);
const auth_token = urlParams.get('token');
const socket = io(process.env.REACT_APP_BACKEND_SERVER_URL || '', { path: process.env.REACT_APP_BACKEND_SERVER_PATH || '/socket.io', query: { token: auth_token }});

interface MessageHandlerProps {
  isDarkMode: boolean;
  setIsDarkMode: React.Dispatch<React.SetStateAction<boolean>>;
  isSidebarVisible: boolean;
  setIsSidebarVisible: React.Dispatch<React.SetStateAction<boolean>>;
  selectedToolkit: string;
  setSelectedToolkit: (toolkit: string) => void;
  selectedModel: string;
  messages: Message[];
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;
}

const MessageHandler: React.FC<MessageHandlerProps> = ({
  isDarkMode,
  setIsDarkMode,
  isSidebarVisible,
  setIsSidebarVisible,
  selectedToolkit,
  setSelectedToolkit,
  selectedModel,
  messages,
  setMessages
}) => {
  const [conversationId, setConversationId] = useState<string>(v4);
  const [userEmail, setUserEmail] = useState<string>('danielpcowen@gmail.com');
  const [input, setInput] = useState<string>('');
  const [canSendMessage, setCanSendMessage] = useState<boolean>(true);

  useEffect(() => {
    socket.on('connect', () => {
      console.log('Socket.IO Client Connected');
    });

    socket.on('chat_completion_stream_return', (chunk: any) => {
      setMessages((prevMessages) => {
        const lastMessageIndex = prevMessages.length - 1;
        const lastMessage = prevMessages[lastMessageIndex];

        if (lastMessage && lastMessage.loading) {
          const updatedMessage = { ...lastMessage, content: lastMessage.content + chunk };
          const updatedMessages = [...prevMessages];
          updatedMessages[lastMessageIndex] = updatedMessage;
          return updatedMessages;
        } else {
          return [
            ...prevMessages,
            { role: 'asssistant', content: chunk, loading: true }
          ];
        }
      });
    });

    socket.on('send_image', (chunk: any) => {
      setMessages((prevMessages) => {
        const lastMessageIndex = prevMessages.length - 1;
        const updatedMessages = [...prevMessages];
        
        updatedMessages[lastMessageIndex].files = [
          {
          filename: "created_image.png",
          contents: new Blob(),
          base64: chunk
        }]
        updatedMessages[lastMessageIndex].loading = false
        return updatedMessages
      });
    });
    
    socket.on('re_enable_message_sending', () => {
      setCanSendMessage(true);
      setMessages((prevMessages) => {
        const lastMessageIndex = prevMessages.length - 1;
        const updatedMessages = [...prevMessages];
        if (updatedMessages[lastMessageIndex].loading) {
          updatedMessages[lastMessageIndex].loading = false;
        }
        return updatedMessages;
      });
    });

    return () => {
      socket.off('connect');
      socket.off('chat_completion_stream_return');
      socket.off('re_enable_message_sending');
    };
  }, []);

  const handleSend = (message: { text: string; files: FileMetadata[] }) => {
    if ((message.text.trim() || message.files) && canSendMessage) {
      const newMessage: Message = { role: 'user', content: message.text, files: message.files };
      const updatedMessages = [...messages, newMessage];
      setMessages(updatedMessages);

      // Format the messages for the payload
      const formattedMessages = updatedMessages.map((message) => {
        const hasImages = message.files?.some(file => 
          file.filename.toLowerCase().endsWith('.png') ||
          file.filename.toLowerCase().endsWith('.jpg') ||
          file.filename.toLowerCase().endsWith('.jpeg')
        );

        if (!hasImages || message.role == 'assistant') {
          return {
            role: message.role,
            content: message.content,
          };
        } else {
          const content = [
            {
              type: "text",
              text: message.content,
            },
            ...message.files!.filter(file =>
              file.filename.toLowerCase().endsWith('.png') ||
              file.filename.toLowerCase().endsWith('.jpg') ||
              file.filename.toLowerCase().endsWith('.jpeg')
            ).map(file => ({
              type: "image_url",
              image_url: {
                url: file.base64!,
              },
            }))
          ];

          return {
            role: message.role,
            content,
          };
        }
      });

      const payload = {
        id: conversationId,
        user: userEmail,
        model: selectedModel,
        messages: [
          {
            role: 'system',
            content: 'You are a helpful bot whose key objective is to assist the user with their queries.',
          },
          ...formattedMessages
        ],
        temperature: 0.3,
        scrapeLinks: true,
        webSearch: selectedToolkit === "Web Search",
        cacheContext: true,
        toolkit: selectedToolkit.toLowerCase().replace(/ /g, '_'), // Include the toolkit selection in the payload
      };

      socket.emit('chat_completion_request', payload);
      setInput('');
      setCanSendMessage(false);
      setMessages((prevMessages) => [
        ...prevMessages,
        { role: 'assistant', content: '', loading: true },
      ]);
    }
  };

  return (
    <div className={`flex-grow flex flex-col mt-16 transition-all duration-300 ${isSidebarVisible ? 'sidebar-open' : ''}`}>
      <ChatPanel messages={messages} />
      <PromptBox input={input} setInput={setInput} handleSend={handleSend} canSendMessage={canSendMessage} />
    </div>
  );
};

export default MessageHandler;
